.e3-googlemap {
    height: 350px;
    width:100%;
    margin: 0px;
    padding: 0px
}

.e3-googlemap_marker {
    &-image {
        padding-right: 0.75rem;
    }
    &-heading {

    }
    &-description {

    }
}
