.e3-comment {
	margin-top: 10px;
	background-color: #f6f6f6;
	border: 1px solid #e1e1e1;
	border-radius: 4px;

	&.e3-comment-answer {
		background-color: transparent;
		border: none;
	}
}

.e3-comment-content {
	border-radius: 4px;
	padding: 5px 10px;
}

.e3-comment-body {
	textarea {
		width: 100%;
		min-height: 200px;
		resize: vertical;
	}
}

.e3-comment-answer {
	&:before {
		left: -20px;
		top: 15px;
	}
}