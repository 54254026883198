.e3-video {
	position: relative;

	&-icon {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 50px;
		height: 50px;
		margin: auto;
	}
}