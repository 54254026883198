@charset "UTF-8";
@font-face {
    font-family: 'Gotham-Black';
    src: url('/echelon/realestate/fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'), url('/echelon/realestate/fonts/Gotham-Black.otf') format('opentype'), url('/echelon/realestate/fonts/Gotham-Black.woff') format('woff'), url('/echelon/realestate/fonts/Gotham-Black.ttf') format('truetype'), url('/echelon/realestate/fonts/Gotham-Black.svg#Gotham-Black') format('svg');
    font-weight: normal;
    font-style: normal;
}  
@font-face { 
    font-family: 'Gotham-Book';
    src: url('/echelon/realestate/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'), url('/echelon/realestate/fonts/Gotham-Book.otf') format('opentype'), url('/echelon/realestate/fonts/Gotham-Book.woff') format('woff'), url('/echelon/realestate/fonts/Gotham-Book.ttf') format('truetype'), url('/echelon/realestate/fonts/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

html.e3-realestate-admin{
    font-size:100%;
    body{
        font-family: 'Gotham-Book', Arial, Tahoma, Verdana, Helvetica, sans-serif;
        font-size: 95%;
        line-height: 1.5em;
        -webkit-font-smoothing: antialiased;
        height: 100vh;
        color: #4e4e4e;
        background: #20c063 url("/echelon/realestate/images/realEstateBg.jpg") center 0 no-repeat;
        background-size: cover;
    }
    .fa-input {
        font-family: FontAwesome, 'open_sansregular', Helvetica, Arial, sans-serif;
    }
    a,
    a:link,
        a:visited { 
        color: #4e4e4e;
        -webkit-transition: all 300ms linear;
        transition: all 300ms linear;
    }
    a:hover {
        color: #20c063;
        text-decoration: none;
    }
    h1,
    h2,
    h3,
    h3 {
        margin: 0;
        padding: 0;
    }
    .content {
        position: absolute;
        width: 100%;
        overflow: hidden;
    }
    .mainNav {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .boxBase {
        width: 100%;
        height: 100%;
    }
    .positionAbsTop {
        top: 0;
        left: 0;
    }
    .positionAbs15 {
        top: 15px;
        left: 15px;
    }
    .positionAbsLeft15 {
        top: 0;
        left: 15px;
    }
    .positionAbsTop15 {
        top: 15px;
        left: 0;
    }
    .box_margin {
        margin-bottom: 30px;
    }
    @media screen and (max-width: 1366px) {
        .box_margin {
            margin-bottom: 15px;
        }
    }
    .box_margin2 {
        margin-bottom: 15px;
    }
    .box_margin3 {
        margin-bottom: 60px;
    }
    @media screen and (max-width: 1366px) {
        .box_margin3 {
            margin-bottom: 30px;
        }
    }
    @media screen and (max-width: 991px) {
        .box_margin3 {
            margin-bottom: 15px;
        }
    }
    .basePadding {
        padding: 30px;
    }
    @media screen and (max-width: 1200px) {
        .basePadding {
            padding: 15px;
        }
    }
    .button_padding {
        padding: 15px 30px;
    }
    @media screen and (max-width: 1200px) {
        .button_padding {
            padding: 5px 15px;
        }
    }
    .boxVertical {
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .boxHorizontal {
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .box_vAlign {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .backgroundCover {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 50%;
    }
    .backgroundContain {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center 50%;
    }
    .radiusMain {
        border-radius: 3px;
    }
    @media screen and (max-width: 991px) {
        .paddingBox_wrapper {
            margin-left: -5px;
            margin-right: -5px;
        }
        .paddingBox_wrapper .paddingBox {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    @media screen and (max-width: 480px) {
        .paddingBox_wrapper .paddingBox {
            width: 100%;
        }
        .paddingBox_wrapper .paddingBox:first-child {
            margin-bottom: 5px;
        }
    }
    .select_arrow {
        position: relative;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: url("../images/select-arrow.png") right 50% no-repeat;
    }
    .select_arrow_padding {
        background-position: 95% 50%;
    }
    input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
        -webkit-transition-delay: 9999s;
        -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    }
    @media only screen and (max-width: 480px) {
        .matchHeight {
            height: auto !important;
        }
    }
    .transition {
        -webkit-transition: all 300ms linear;
        transition: all 300ms linear;
    }
    .boxShadow {
        behavior: url(PIE.htc);
        position: relative;
        zoom: 1;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    }
    .boxShadowBottom {
        behavior: url(PIE.htc);
        position: relative;
        zoom: 1;
        box-shadow: 0 9px 7px -7px rgba(0, 0, 0, 0.1);
    }
    .boxCircle {
        behavior: url(PIE.htc);
        position: relative;
        zoom: 1;
        border-radius: 50%;
    }
    .login-page-wrapper {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        .login-page-wrapper {
            padding: 10px;
        }
    }
    .login-wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    @media screen and (max-width: 991px) {
        .login-wrapper {
            display: block;
        }
    }
    .login-page-left {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 0 10%;
    }
    @media screen and (max-width: 991px) {
        .login-page-left {
            position: relative;
            top: 0;
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0);
            padding: 0;
        }
    }
    .login-page-left h2 {
        font-family: 'Gotham-Black', Arial, Tahoma, Verdana, Helvetica, sans-serif;
        font-size: 300%;
        color: #ffffff;
        line-height: 1.4em;
    }
    @media screen and (max-width: 1366px) {
        .login-page-left h2 {
            font-size: 220%;
        }
    }
    @media screen and (max-width: 991px) {
        .login-page-left h2 {
            font-size: 180%;
            text-align: center;
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 767px) {
        .login-page-left h2 {
            font-size: 150%;
        }
    }
    @media screen and (max-width: 414px) {
        .login-page-left h2 {
            font-size: 120%;
        }
    }
    .login-page-form {
        max-width: 700px;
        color: #4e4e4e;
        background-color: #ffffff;
        padding: 35px;
        border-radius: 3px;
    }
    @media screen and (max-width: 991px) {
        .login-page-form {
            min-width: 350px;
            margin: 0 auto;
            padding: 15px;
        }
    }
    @media screen and (max-width: 480px) {
        .login-page-form {
            min-width: auto;
        }
    }
    .login-page-form h3 {
        position: relative;
        display: inline-block;
        font-size: 135%;
        letter-spacing: 4px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .login-page-form h3:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 135px;
        height: 3px;
        content: "";
        background-color: #20c063;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    @media screen and (max-width: 1366px) {
        .login-page-form h3:after {
            width: 100px;
        }
    }
    @media screen and (max-width: 1366px) {
        .login-page-form h3 {
            font-size: 120%;
            letter-spacing: 3px;
            padding-top: 25px;
            padding-bottom: 20px;
        }
    }
    @media screen and (max-width: 991px) {
        .login-page-form h3 {
            font-size: 105%;
            letter-spacing: 2px;
        }
    }
    @media screen and (max-width: 1366px) {
        .login-page-form .main_logo img {
            max-width: 200px;
        }
    }
    @media screen and (max-width: 991px) {
        .login-page-form .main_logo img {
            max-width: 160px;
        }
    }
    @media screen and (max-width: 991px) {
        .login-page-form .login-page-form-text {
            font-size: 90%;
        }
    }
    .login-form .form-control,
    .login-form .btn {
        height: 54px;
        box-shadow: none;
        border: 0 solid #ffffff;
        border-bottom: 1px solid #ececec;
        border-radius: 0;
    }
    @media screen and (max-width: 1366px) {
        .login-form .form-control,
        .login-form .btn {
            height: 36px;
        }
    }
    .login-form .form-group {
        position: relative;
    }
    .login-form .form-group:before {
        position: absolute;
        top: 50%;
        left: 0;
        font-family: "FontAwesome";
        font-size: 24px;
        color: #20c063;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    @media screen and (max-width: 1366px) {
        .login-form .form-group:before {
            font-size: 18px;
        }
    }
    .login-form .form-group.form-group-user:before {
        content: "\f007";
    }
    .login-form .form-group.form-group-password:before {
        content: "\f023";
    }
    .login-form input {
        font-size: 115%;
        color: #4e4e4e;
        background-color: transparent;
        padding: 0 12px 0 30px;
    }
    @media screen and (max-width: 1366px) {
        .login-form input {
            font-size: 105%;
            padding: 0 10px 0 25px;
        }
    }
    .login-form input::-moz-placeholder {
        color: #4e4e4e;
        opacity: 1;
    }
    .login-form input:-ms-input-placeholder {
        color: #4e4e4e;
    }
    .login-form input::-webkit-input-placeholder {
        color: #4e4e4e;
    }
    .login-form .contactFormButton {
        padding: 12px 0;
        height: auto;
        display: block;
        width: 100%;
        font-family: 'Gotham-Black', Arial, Tahoma, Verdana, Helvetica, sans-serif;
        font-size: 120%;
        color: #ffffff;
        border-width: 0;
        border-radius: 200px;
        -webkit-transition: all 300ms linear;
        transition: all 300ms linear;
    }

    .login-form .contactFormButton {
        @media screen and (max-width: 1366px) {
            font-size: 105%;
        }
        @media screen and (max-width: 1200px) {
            font-size: 100%; 
        }
        @media screen and (max-width: 1100px) {
            font-size: 90%; 
        }
    } 

    .login-form .contactFormButton01 {
        background-color: #20c063;
    }
    .login-form .contactFormButton01:hover {
        background-color: #1a9d51;
    }
    .login-form .contactFormButton02 {
        background-color: #ff7857;
    }
    .login-form .contactFormButton02:hover {
        background-color: #ff572e;
    }
    .login-form-nav {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .login-form-nav li a {
        position: relative;
        display: inline;
        text-decoration: none;
        padding-left: 20px;
    }
    .login-form-nav li a:hover {
        text-decoration: none;
    }
    .login-form-nav li a:before {
        position: absolute;
        top: 0;
        left: 0;
        font-family: "FontAwesome";
        font-size: 15px;
        color: #20c063;
    }
    .login-form-nav li:first-child a:before {
        content: "\f084";
    }
    .login-form-nav li:last-child a:before {
        content: "\f048";
    }
}