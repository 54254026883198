.e3-form-policy {
	cursor: pointer;

	&-toggle {
		top: 1px;
	}

	&-title {
		text-decoration: underline;
		color: #00f;
	}

	&-text {
		margin-top: 5px;
		display: none;
		font-size: 95%;
	}
}