.e3cookie-note-wrapper {
	position: relative;
	z-index: 1000000;
	background: transparent;
	width: 100%;
	text-align: center;
	font-size: .9rem;

	.e3cookie-note {
		color: #fff;
		background: #446587;
		padding: 10px;
	}
}