.e3cookie-modal-wrapper {
    position: relative;
    width: 100%;
   
    z-index: 100000000;
    

    h3 {
        @media screen and (max-width: 580px) {
            font-size: 1.5rem;
            text-align: center;
        }
    }



    .e3cookie-modal {
        padding: 20px 0;
        height: 100%;

        h3 {
            color: #446587;
        }
        .buttons-container{
            button {
                @media screen and (max-width: 540px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }

        &-spinner {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 40px;
            height: 40px;
        }


    }

    &.e3cookie-modal-loading {
        .e3cookie-modal-content {
            opacity: 0.1;
        }

        .e3cookie-modal-spinner {
            display: block;
        }
    }


}