.e3-gallery-full-preview {
	position: relative;

	&-content-wrapper {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
		transform: translateY(-50%);
	}

	&-content {
		max-width: 300px;
		padding: 30px 15px;
		margin: 0 auto;
		background-color: rgba(255, 255, 255, .85);
	}

	&-link {
		display: block;

		&:hover {
			text-decoration: none;
		}
	}

	&-button {
		margin-top: 10px;
		padding: 4px 12px;
		text-transform: uppercase;
		color: #fff;
	}
}

.e3-gallery-full {
	&-logo {
		&:hover {
			text-decoration: none;
		}
	}

	&-title {
		font-size: 1.6rem;
		margin: 0 10px;

		@media screen and (max-width: 767px) {
			order: 2;
			width: 100%;
			margin-top: 10px;
			font-size: 1.3rem;
		}
	}

	&-close {
		@media screen and (max-width: 767px) {
			order: 1;
		}
	}

	&-image {
		position: relative;
	}

	&-control {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		padding: 4px 8px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, .5);
		z-index: 10;
		font-size: 1.2rem;
		transition: all .2s ease-out;

		&:hover {
			background-color: rgba(255, 255, 255, .7);
		}
	}

	&-control-left {
		left: 20px;
		padding-right: 12px;
	}

	&-control-right {
		right: 20px;
		padding-left: 12px;
	}

	&-thumbs {
		margin-top: 12px;
	}
}