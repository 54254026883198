@main-color: #446587;
@second-color: #73879c;

.e3-login {
	max-width: 450px;
	margin: 0 auto;
	color: @main-color;

	&-title {
		color: @main-color;

		@media screen and (max-width: 1200px) {
			font-size: 2rem;
		}

		@media screen and (max-width: 580px) {
			font-size: 1.7rem;
		}
	}

	&-flag-link {
		&:hover {
			text-decoration: none;
		}
	}

	&-text {
		color: @second-color;
		padding: 10px;
	}

	&-link {
		font-size: .9rem;
	}
}